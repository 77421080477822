@import "./bootstrap.css";
@import "./keyframes.scss";
@import "./fontawesome-all.css";

@font-face {
  font-family: "SSNbold";
  src: url("../fonts/SharpSansNo1Bold.otf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "SSNbook";
  src: url("../fonts/SharpSansNo1Book.otf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "SSNmedium";
  src: url("../fonts/SharpSansNo1Medium.otf") format("opentype"); /* Safari, Android, iOS */
}

$primaryColor: #ffffff;
$textColor: #5c6670;
$textColorLight: #a4a9ad;
$accColor: #c4d600;
$checkColor: #39b54a;
$bgColor: #1e252b;
$formInput: #e1e5e5;
$formInfo: #00b3e3;
$formFailed: #ff5000;

#loader {
  position: fixed;
  width: 100%;
  height: 120vh;
  min-height: 900px;
  background-color: $bgColor;
  z-index: 1000;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .container-fluid {
    position: relative;
    top: -10vh;
  }

  .load-holder {
    width: 286px;
    height: 252px;
    position: relative;
    margin: auto;

    img {
      width: 100%;
      height: 100%;
      animation: pulse 0.5s infinite ease-in-out;
    }

    .bars {
      width: 26px;
      height: 3px;
      position: absolute;
      bottom: 44px;
      margin: auto;
      animation: loading 0.5s ease-in-out both;

      &.bar1 {
        left: 90px;
        animation-delay: 0.25s;
        background-color: #00b3e3;
      }

      &.bar2 {
        left: 0;
        right: 0;
        animation-delay: 0.5s;
        background-color: #c4d600;
      }

      &.bar3 {
        right: 90px;
        animation-delay: 0.75s;
        background-color: #ff5000;
      }
    }
  }

  .load-text {
    position: relative;

    p {
      width: 100%;
      text-align: center;
      color: $textColor;
      font-size: 3.6rem;
      font-family: "SSNbook";
      letter-spacing: 4.5px;
      margin: 45px 0;
      height: 50px;
    }

    .curtain {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: $bgColor;
      animation: curtain 0.5s ease-in-out both;
    }
  }

  &.loaded {
    animation: fadeOut 0.5s ease-in-out both;
  }
}

#alert {
  position: fixed;
  z-index: 999;
  display: flex;
  align-self: center;
  justify-content: center;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
  width: 300px;
  height: 50px;
  padding: 10px;
  border-radius: 5px;
  display: none;

  &.success {
    display: block;
    background-color: $formInfo;
  }

  &.failed {
    display: block;
    background-color: $formFailed;
  }

  p {
    font-size: 2rem;
    font-family: "SSNmedium";
    color: $primaryColor;
    margin: 0;
  }
}

body {
  font-size: 10px;
  font-family: "sans-serif";
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  min-height: 1000px;
  margin: 0;
}

header {
  width: 100%;
  height: 120vh;
  min-height: 900px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  z-index: 1;

  .container-fluid {
    position: relative;
    top: -10vh;
  }

  .languages {
    display: flex;
    position: absolute;
    top: 10vh;
    left: 0;
    right: 0;
    margin: auto;
    justify-content: center;
    z-index: 11;

    a {
      display: block;
      width: 80px;
      padding: 10px 20px;
      font-size: 2.8rem;
      font-family: "SSNbook";
      text-align: center;
      color: $textColor;
      cursor: pointer;
      position: relative;
      text-decoration: none;

      &:hover,
      &.active {
        font-family: "SSNbold";
      }

      &:first-child:after {
        content: "";
        position: absolute;
        width: 2px;
        height: 30px;
        top: 0;
        bottom: 0;
        right: -1px;
        margin: auto;
        background-color: $textColor;
      }
    }
  }

  .logo {
    margin: auto;
    text-align: center;
    position: relative;
    z-index: 10;
    animation: pulse 3s infinite ease-in-out;

    img {
      width: 286px;
      height: 252px;
    }

    h1 {
      color: $textColor;
      font-size: 3.6rem;
      font-family: "SSNbook";
      letter-spacing: 4.5px;
      margin: 45px 0;
      height: 50px;
    }
  }

  .forward {
    position: relative;
    bottom: -15vh;
    left: 0;
    right: 0;
    margin: auto;
    width: 80px;
    cursor: pointer;
    z-index: 10;

    img {
      width: 100%;
      position: absolute;
      animation: slide 1.5s linear infinite;

      &:nth-child(2) {
        animation-delay: 0.5s;
      }
      &:nth-child(3) {
        animation-delay: 1s;
      }
    }
  }

  .header-bg {
    width: 120%;
    height: 120%;
    position: absolute;
    bottom: 8%;
    left: -10%;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    willchange: all;

    &.bg1 {
      z-index: 5;
      background-image: url("../backgrounds/header-bg1.svg");
      animation-name: cellularMove1;
      animation-duration: 25s;
      bottom: 5%;
    }

    &.bg2 {
      z-index: 4;
      background-image: url("../backgrounds/header-bg2.svg");
      animation-name: cellularMove2;
      animation-duration: 25s;
    }

    &.bg3 {
      z-index: 3;
      background-image: url("../backgrounds/header-bg3.svg");
      animation-name: cellularMove1;
      animation-duration: 30s;
    }

    &.bg4 {
      z-index: 2;
      background-image: url("../backgrounds/header-bg4.svg");
      animation-name: cellularMove2;
      animation-duration: 30s;
    }

    &.bg5 {
      z-index: 1;
      background-image: url("../backgrounds/header-bg5.svg");
      animation-name: cellularMove1;
      animation-duration: 35s;
    }
  }
}

section {
  color: $textColor;
  display: flex;
  align-items: center;

  & > div {
    width: 100%;
  }

  h1 {
    font-size: 8.2rem;
    font-family: "SSNbold";
    margin: 0;
  }

  h2 {
    font-size: 7.2rem;
    font-family: "SSNbook";
    text-transform: uppercase;
    margin: 0;
  }

  h4 {
    font-size: 3.6rem;
    font-family: "SSNbold";
    margin: 0;
  }

  h6 {
    font-size: 1.8rem;
    font-family: "SSNbook";
    text-transform: uppercase;
    margin: 0;
  }

  p {
    font-size: 2.4rem;
    font-family: "SSNmedium";
    margin: 0;
  }

  &.bg {
    h1,
    h2,
    h4,
    h6 {
      color: $primaryColor;
    }

    p {
      color: $textColorLight;
    }
  }

  .Plx {
    height: 100vh;
    min-height: 800px;
    display: flex;

    &--below {
      .left {
        animation: slideFromLeft 1s cubic-bezier(0.17, 0.84, 0.44, 1) 1 both;
      }

      .right {
        animation: slideFromRight 1s cubic-bezier(0.17, 0.84, 0.44, 1) 1 both;
        z-index: 999;
      }
    }
  }
}

#about {
  position: relative;
  margin-top: 34px;
  padding-bottom: 25px;
  .video-container {
    position: relative;
    max-width: 1280px;
    width: 100%;
    height: 720px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    .video {
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    .front {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      align-content: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      background-image: url("../backgrounds/video-bg.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      .left {
        display: flex;
        align-items: center;
        margin-right: 45px;
        i {
          &:hover {
            transform: scale(1.1);
          }
          cursor: pointer;
          display: inline-block;
          position: relative;
          width: 219px;
          height: 219px;
          border-radius: 50%;
          background-image: url("../backgrounds/play.svg");
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          transition: transform 0.3s ease-out;
          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 219px;
            height: 219px;
            border-radius: 50%;
            border-style: solid;
            border-width: 3px;
            border-color: white;
            box-sizing: border-box;
            -webkit-animation: scale-before 2s infinite ease;
            animation: scale-before 2s infinite ease;
          }

          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 219px;
            height: 219px;
            border-radius: 50%;
            border-style: solid;
            border-width: 3px;
            border-color: white;
            box-sizing: border-box;
            animation: scale-after 2s infinite 0.3s;
          }

          &:nth-of-type(2) {
            &:before {
              animation-delay: 0.3s;
            }

            &:after {
              animation-delay: 0.6s;
            }
          }

          &:nth-of-type(3) {
            &:before {
              animation-delay: 0.6s;
            }

            &:after {
              animation-delay: 0.9s;
            }
          }
        }

        /*//////// Animations ////////*/

        @keyframes scale-before {
          0% {
            transform: scale(0.5);
            opacity: 1;
          }

          40% {
            opacity: 0.5;
          }

          100% {
            transform: scale(1.2);
            opacity: 0;
          }
        }

        @keyframes scale-after {
          0% {
            transform: scale(0.5);
            opacity: 1;
          }

          40% {
            opacity: 0.5;
          }

          100% {
            transform: scale(1.5);
            opacity: 0;
          }
        }
      }
      .right {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        color: $primaryColor;
        justify-content: center;
        margin-left: 45px;
        h2 {
          position: relative;
          font-family: "SSNbold";
          font-size: 7rem;
          cursor: default;
          padding-bottom: 25px;
          &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            width: 60.5%;
            background-color: $primaryColor;
          }
        }
        span {
          font-family: "SSNbook";
          font-size: 3.5rem;
          cursor: default;
          padding-top: 29px;
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
  .video-description {
    max-width: 794px;
    font-size: 18px;
    text-align: center;
    line-height: 21px;
    margin: 36px auto 36px auto;
    padding: 0 15px;

    b {
      font-family: "SSNbold";
    }
  }
  .show-video {
    .front {
      display: none;
    }
  }
  .side-image {
    width: 1040px;
    height: 920px;
    position: absolute;
    bottom: 0;
    left: 50vw;
    background-image: url("../backgrounds/Macbook-Pro.png");
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
  }

  .Plx {
    align-items: center;

    &--below {
      p,
      h2 {
        animation: slideFromLeft 1s cubic-bezier(0.17, 0.84, 0.44, 1) 1 both;
      }

      .side-image {
        animation: slideFromRight 1s cubic-bezier(0.17, 0.84, 0.44, 1) 1 both;
      }
    }
  }
}

#properties {
  position: relative;
  background-color: $bgColor;
  margin: 420px 0;
  flex-direction: column;

  .Plx {
    flex-direction: column;
    justify-content: center;

    &.prop {
      min-height: 1000px;
    }

    &--below {
      .block-holder {
        .blocks {
          animation-delay: 2s;
        }

        .block1.left {
          animation: angleFromLeft1 1s cubic-bezier(0.17, 0.84, 0.44, 1) 1 both;
        }

        .block2.left {
          animation: angleFromLeft2 1s cubic-bezier(0.17, 0.84, 0.44, 1) 1 both;
        }

        .block3.left {
          animation: angleFromLeft3 1s cubic-bezier(0.17, 0.84, 0.44, 1) 1 both;
        }
      }
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 420px;
    left: 0;
    right: 0;
    margin: auto;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &::before {
    top: -418px;
    background-image: url("../backgrounds/acc-bg1.svg");
    background-position: bottom center;
  }

  &::after {
    bottom: -418px;
    background-image: url("../backgrounds/acc-bg2.svg");
    background-position: top center;
  }

  .accordion {
    margin-top: 100px;
    border: 0;

    &__title {
      background-color: unset;
      color: $primaryColor;
      position: relative;
      padding-left: 40px;
      outline: 0;

      &:before {
        content: "\F067";
        position: absolute;
        left: -20px;
        display: flex;
        align-self: center;
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        font-size: 5rem;
        display: inline-block;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
      }
    }

    &__title[aria-selected="true"] {
      color: $accColor;

      &:before {
        content: "\F068";
      }
    }

    &__item {
      border: 0;
    }
  }

  .ocr {
    position: relative;
    flex-direction: column;
    margin-top: 150px;
    width: 100%;
    max-height: 1200px;
    background-size: 60% 80%;
    height: auto !important;

    h1 {
      width: 100%;
      text-align: right;
      position: relative;
      margin-top: 30px;
      margin: auto;
      line-height: 95px;
      z-index: 5;
    }

    .block-holder {
      height: 950px;
      position: absolute;
      display: flex;
      top: 0;
      left: 12%;

      .blocks {
        position: absolute;
        width: 150px;
        height: 100%;
        transform: skew(-27deg);
      }

      .block1 {
        background-color: rgba(255, 80, 0, 1);
        z-index: 3;
      }

      .block2 {
        background-color: rgba(196, 214, 0, 1);
        z-index: 2;
      }

      .block3 {
        background-color: rgba(0, 179, 227, 1);
        z-index: 1;
      }
    }

    .prop-holder {
      margin: 70px auto 0;
      position: relative;
      z-index: 5;

      img {
        width: 850px;
        height: 795px;
        position: relative;
        left: -20px;
      }

      .props {
        position: absolute;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        animation-delay: 0.3s;
        white-space: nowrap;

        &::after {
          content: "";
          position: absolute;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }

        span {
          font-size: 6rem;
          color: $primaryColor;
        }

        p {
          font-size: 2.1rem;
          position: absolute;
          font-family: "SSNbold";
          color: $primaryColor;
          text-transform: uppercase;
        }

        &.prop1 {
          width: 163.5px;
          height: 163.5px;
          background-color: rgba(0, 179, 227, 0.5);
          top: 0px;
          left: -140px;
          background-image: url("../backgrounds/prop1.svg");
          background-repeat: no-repeat;
          background-size: 50%;
          background-position: center;

          p {
            right: -35%;
            top: 25%;
          }

          &::after {
            width: 154px;
            height: 74px;
            background-image: url("../backgrounds/prop1-link.svg");
            right: -80%;
            top: 45%;
          }
        }

        &.prop2 {
          width: 183px;
          height: 183px;
          top: 0;
          right: -170px;
          background-color: rgba(196, 214, 0, 0.5);

          p {
            top: 10%;
            left: -60%;
          }

          &::after {
            width: 296px;
            height: 262px;
            background-image: url("../backgrounds/prop2-link.svg");
            left: -145%;
            top: 45%;
          }
        }

        &.prop3 {
          width: 174.6px;
          height: 174.6px;
          bottom: 20px;
          right: 15px;
          background-color: rgba(255, 80, 0, 0.5);

          p {
            top: -20%;
            right: 10%;
          }

          &::after {
            width: 141px;
            height: 42px;
            background-image: url("../backgrounds/prop3-link.svg");
            top: 30%;
            left: -55%;
          }
        }
      }
    }
  }
}

#solutions {
  padding-top: 240px;

  .scroll-container {
    display: flex;
    align-items: center;

    .Plx {
      width: 100%;
      height: unset;
      align-items: center;
      justify-content: center;
      margin: auto;
    }

    &:first-child {
      .Plx {
        flex-direction: column;

        .columns {
          width: 100%;
          margin-top: 200px;
        }
      }
    }
  }

  h2 {
    text-align: center;
  }

  h4 {
    text-transform: uppercase;
    padding-left: 30px;
    margin-bottom: 10px;
  }

  p {
    padding-left: 30px !important;
    padding: 10px 0;
    display: flex;
    position: relative;

    i {
      position: absolute;
      line-height: 34px;
      left: -10px;
      color: $checkColor;
    }
  }

  .pic {
    min-height: 580px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .bg {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 0;
    }

    img {
      position: relative;
      z-index: 1;
    }

    &.municiple {
      .bg {
        background-image: url("../backgrounds/municiple-bg.svg");
      }

      img {
        width: 90%;
        left: 10px;
        top: 10px;
      }
    }

    &.law {
      .bg {
        background-image: url("../backgrounds/law-bg.svg");
      }

      img {
        width: 60%;
        right: 10px;
      }
    }

    &.construct {
      .bg {
        background-image: url("../backgrounds/construct-bg.svg");
      }

      img {
        width: 70%;
        left: 40px;
        bottom: 10px;
      }
    }
  }

  .Plx--below {
    .bg {
      animation: bloom 300ms linear both;
      animation-delay: 0.15s;
    }
  }
}

#others {
  margin-top: 150px;

  .Plx {
    min-height: 1300px;
    flex-direction: column;
  }

  .container-fluid {
    margin-top: 0;

    & > p {
      text-align: center;
      line-height: 30px;
      margin: 20px 0;
    }

    .mobileP {
      display: none;
      margin: 0;
    }
  }

  h4 {
    text-transform: uppercase;
    text-align: center;
  }

  .wrapper {
    position: relative;
    margin-top: 120px;
    height: calc(100% - 230px);
    display: flex;
    align-items: center;
    justify-content: center;

    .center-prop {
      position: relative;
      width: 700px;
      height: 700px;
      display: flex;
      align-items: center;
      justify-content: center;

      .bgi {
        position: absolute;
        background-repeat: no-repeat;
        background-position: center;

        &.bg1 {
          width: 575px;
          height: 613px;
          background-image: url("../backgrounds/prop-bg1.svg");
          z-index: 5;
          animation: cellularBgLeft 10s ease-in-out infinite;
        }

        &.bg2 {
          width: 545px;
          height: 530px;
          background-image: url("../backgrounds/prop-bg2.svg");
          z-index: 4;
          animation: cellularBgRight 15s ease-in-out infinite;
        }

        &.bg3 {
          width: 548px;
          height: 534px;
          background-image: url("../backgrounds/prop-bg3.svg");
          z-index: 3;
          animation: cellularBgLeft 20s ease-in-out infinite;
        }

        &.bg4 {
          width: 542px;
          height: 552px;
          background-image: url("../backgrounds/prop-bg4.svg");
          z-index: 2;
          animation: rotateBgRight 25s ease-in-out infinite;
        }

        &.bg5 {
          width: 542px;
          height: 520px;
          background-image: url("../backgrounds/prop-bg5.svg");
          z-index: 1;
          animation: cellularBgLeft 30s ease-in-out infinite;
        }
      }

      img {
        width: 286px;
        height: 252px;
        position: relative;
        z-index: 10;
        animation: pulse 3s infinite ease-in-out;
      }
    }

    .prop {
      position: absolute;

      &:hover {
        img {
          transition: transform 0.5s;
          transform: scale3d(1.1, 1.1, 1);
        }
      }

      &.p1 {
        width: 160px;
        height: 160px;
        top: 0;
        left: 27%;
      }

      &.p2 {
        width: 250px;
        height: 240px;
        top: 40px;
        right: 14%;
      }

      &.p3 {
        width: 140px;
        height: 135px;
        right: 0;
        top: 80px;
      }

      &.p4 {
        width: 165px;
        height: 160px;
        right: 0;
        top: 0;
        bottom: 20px;
        margin: auto;
      }

      &.p5 {
        width: 187px;
        height: 180px;
        right: 16%;
        bottom: 200px;
      }

      &.p6 {
        width: 165px;
        height: 165px;
        bottom: 0;
        left: 8%;
        right: 0;
        margin: auto;
      }

      &.p7 {
        width: 243px;
        height: 246px;
        left: 13%;
        bottom: 50px;
      }

      &.p8 {
        width: 146px;
        height: 145px;
        top: 200px;
        bottom: 0;
        left: 4%;
        margin: auto;
      }

      &.p9 {
        width: 330px;
        height: 315px;
        top: 160px;
        left: 0;
      }
    }

    h6 {
      position: absolute;
      text-align: center;
      letter-spacing: 3px;
      height: 25px;
      color: $textColorLight;

      &.h1 {
        left: -170px;
        right: 0;
        top: 40px;
        margin: auto;
      }

      &.h2 {
        right: 440px;
        top: 10px;
      }

      &.h3 {
        right: 15px;
        top: -370px;
        bottom: 0;
        margin: auto;
      }

      &.h4 {
        right: 180px;
        top: 20px;
        bottom: 0;
        margin: auto;
      }

      &.h5 {
        right: 260px;
        bottom: 120px;
      }

      &.h6 {
        bottom: 140px;
        left: -320px;
        right: 0;
        margin: auto;
      }

      &.h7 {
        top: 40px;
        bottom: 0;
        left: 190px;
        margin: auto;
      }

      &.h8 {
        left: 80px;
        top: 110px;
      }
    }
  }

  .Plx--below {
    .prop {
      animation: bloom 1000ms linear both;

      &.p1 {
        animation-delay: 0.25s;
      }

      &.p2 {
        animation-delay: 1s;
      }

      &.p3 {
        animation-delay: 0.5s;
      }

      &.p4 {
        animation-delay: 0.5s;
      }

      &.p5 {
        animation-delay: 1s;
      }

      &.p6 {
        animation-delay: 0.75s;
      }

      &.p7 {
        animation-delay: 1s;
      }

      &.p8 {
        animation-delay: 1s;
      }

      &.p9 {
        animation-delay: 1.25s;
      }
    }
  }
}

#advantages {
  background-color: $bgColor;
  padding: 150px 0;
  margin-top: 150px;

  .Plx {
    height: calc(100vh - 300px);
    flex-direction: column;
    justify-content: center;
    min-height: 900px;
  }

  & > .row {
    margin-top: 120px;
  }

  h2 {
    text-align: center;
    margin-bottom: 120px;
  }

  p {
    margin: 20px 0;
  }
}

#demo {
  position: relative;
  height: 690px;
  background-color: $accColor;
  align-items: flex-start;
  justify-content: center;
  padding: 80px 0;

  h4 {
    width: 770px;
    text-align: center;
  }

  .demo-pic {
    position: absolute;
    height: 700px;
    top: 40%;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;

    img {
      max-width: 90%;
    }
  }
}

#contact {
  padding: 110px;
  margin-top: 300px;
  flex-direction: column;

  .address {
    text-align: center;
    padding: 50px 0;

    .info {
      display: flex;
      justify-content: center;
      margin: 20px 0;

      i {
        margin-right: 8px;
        font-style: normal;
        color: $formInfo;
        font-family: "SSNbold";
      }

      p {
        margin: 0 10px;
      }
    }
  }

  .nav {
    span {
      display: inline-block;
      width: 18px;
      height: 18px;
      margin: 0 8px;
      border-radius: 50%;
      background-color: $formInfo;
      cursor: pointer;

      &.active {
        opacity: 0.3;
      }
    }
  }

  form {
    width: 600px;

    input,
    textarea {
      width: 100%;
      border: none;
      border-radius: 2px;
      background-color: $formInput;
      padding: 10px 30px;
      font-size: 2.4rem;
      font-family: "SSNmedium";
    }

    input {
      height: 70px;
      margin-bottom: 20px;
    }

    textarea {
      height: 145px;
      resize: none;
    }

    button {
      width: 100%;
      height: 70px;
      border-radius: 8px;
      color: $primaryColor;
      background-color: $formInfo;
      margin-top: 30px;
      text-transform: uppercase;
      font-size: 2.4rem;
      font-family: "SSNbold";
    }
  }
}

.policies {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.policies a {
  font-size: 14px;
  color: black;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.content h1 {
  font-weight: bold;
  font-size: 50px;
  padding: 0 5%;
  text-align: center;
}

.content h2 {
  font-weight: bold;
  font-size: 34px;
  padding: 0 5%;
}

.content p,
li {
  font-size: 28px;
  color: black;
  padding: 0 5%;
}

.cookies {
  align-self: flex-start;
  padding: 0 5%;
}

.category {
  background-color: #09007b;
}

@media only screen and (min-width: 1824px) {
  //very large screens (set as default)

  .container-fluid {
    padding: 0 240px;
    max-width: 1920px;
    width: 100%;
    margin: auto;
  }

  #properties {
    .ocr {
      max-height: 1200px;
    }
  }
}

@media only screen and (max-width: 1823px) {
  //between very large screens and large screens

  section {
    h1 {
      font-size: 6rem;
    }

    h2 {
      font-size: 5.4rem;
    }

    h4 {
      font-size: 3rem;
    }

    h6 {
      font-size: 1.6rem;
    }

    p {
      font-size: 2rem;
    }
  }

  .container-fluid {
    padding: 0 10%;
  }

  header {
    .forward {
      bottom: -12vh;
    }
  }

  #about {
    .side-image {
      width: 50vw;
      height: 44vw;
      background-size: cover;
    }
  }

  #properties {
    margin: 300px 0;

    &::before,
    &::after {
      height: 300px;
    }

    &::before {
      top: -298px;
    }

    &::after {
      bottom: -298px;
    }

    .accordion {
      margin-top: 50px;

      &__title {
        padding: 12px;
        padding-left: 30px;

        &::before {
          font-size: 4.6rem;
        }
      }
    }

    .ocr {
      margin-top: 100px;
      max-height: 980px;

      h1 {
        line-height: 82px;
      }

      .block-holder {
        height: 780px;

        .blocks {
          width: 120px;
        }
      }

      .prop-holder {
        margin: 55px auto 0;

        img {
          width: 700px;
          height: 650px;
        }

        .props {
          span {
            font-size: 5.2rem;
          }

          p {
            font-size: 1.9rem;
          }

          &.prop1 {
            width: 150px;
            height: 150px;
            top: 0px;
            left: -140px;

            p {
              right: -27%;
              top: 27%;
            }

            &::after {
              width: 140px;
              height: 60px;
              right: -75%;
            }
          }

          &.prop2 {
            width: 165px;
            height: 165px;

            &::after {
              width: 250px;
              height: 230px;
              left: -135%;
            }
          }

          &.prop3 {
            width: 160px;
            height: 160px;
            right: -20px;

            &::after {
              width: 100px;
              left: -45%;
            }
          }
        }
      }
    }
  }

  #solutions {
    padding-top: 180px;

    .scroll-container:first-child {
      .Plx {
        .columns {
          margin-top: 150px;
        }
      }
    }
  }

  #others {
    margin-top: 100px;

    .Plx {
      min-height: 1100px;
    }

    .wrapper {
      height: calc(100% - 185px);
      margin-top: 80px;

      .center-prop {
        width: 480px;
        height: 480px;

        .bgi {
          &.bg1 {
            width: 445px;
            height: 485px;
          }

          &.bg2 {
            width: 415px;
            height: 400px;
          }

          &.bg3 {
            width: 415px;
            height: 405px;
          }

          &.bg4 {
            width: 410px;
            height: 420px;
          }

          &.bg5 {
            width: 410px;
            height: 390px;
          }
        }

        img {
          width: 235px;
          height: 205px;
        }
      }

      .prop {
        &.p1 {
          width: 130px;
          height: 135px;
        }

        &.p2 {
          width: 190px;
          height: 185px;
        }

        &.p3 {
          width: 115px;
          height: 110px;
        }

        &.p4 {
          width: 135px;
          height: 130px;
          bottom: 20px;
        }

        &.p5 {
          width: 155px;
          height: 150px;
          bottom: 170px;
        }

        &.p6 {
          width: 135px;
          height: 135px;
        }

        &.p7 {
          width: 205px;
          height: 205px;
          bottom: 50px;
        }

        &.p8 {
          width: 115px;
          height: 115px;
          top: 200px;
          left: 4%;
        }

        &.p9 {
          width: 280px;
          height: 285px;
          top: 100px;
        }
      }

      h6 {
        &.h1 {
          left: -140px;
          top: 30px;
        }

        &.h2 {
          right: 380px;
        }

        &.h3 {
          top: -250px;
        }

        &.h4 {
          right: 180px;
          top: 20px;
        }

        &.h5 {
          bottom: 90px;
        }

        &.h6 {
          bottom: 90px;
        }

        &.h8 {
          top: 50px;
        }
      }
    }
  }

  #advantages {
    margin-top: 100px;
    padding: 120px 0;

    .Plx {
      height: calc(100vh - 240px);
      min-height: 840px;
    }
  }

  #contact {
    margin-top: 250px;
  }
}

@media only screen and (max-width: 1440px) {
  //desktops and laptops

  section {
    h1 {
      font-size: 6.4rem;
    }

    h2 {
      font-size: 6rem;
    }

    h4 {
      font-size: 3rem;
    }

    h6 {
      font-size: 1.5rem;
    }

    p {
      font-size: 2rem;
    }
  }

  .container-fluid {
    max-width: 1200px;
    padding: 0 15px;
  }

  #loader {
    min-height: 800px;
  }

  header {
    min-height: 800px;

    .forward {
      bottom: -8vh;
    }
  }

  #properties {
    margin: 200px 0;

    &::before,
    &::after {
      height: 200px;
    }

    &::before {
      top: -198px;
    }

    &::after {
      bottom: -198px;
    }

    .accordion {
      &__title {
        padding: 10px;
        padding-left: 50px;

        &::before {
          font-size: 4.2rem;
          left: 0;
        }
      }
    }

    .ocr {
      max-height: 800px;

      h1 {
        line-height: 68px;
      }

      .block-holder {
        height: 650px;

        .blocks {
          width: 90px;
        }
      }

      .prop-holder {
        margin: 40px auto 0;

        img {
          width: 560px;
          height: 520px;
        }

        .props {
          span {
            font-size: 4rem;
          }

          p {
            font-size: 1.6rem;
          }

          &.prop1 {
            width: 130px;
            height: 130px;
            left: -110px;

            &::after {
              width: 90px;
              height: 40px;
              right: -50%;
            }
          }

          &.prop2 {
            width: 145px;
            height: 145px;
            right: -150px;

            &::after {
              width: 210px;
              height: 190px;
              left: -130%;
              top: 40%;
            }
          }

          &.prop3 {
            width: 130px;
            height: 130px;
            right: -40px;

            &::after {
              left: -55%;
            }
          }
        }
      }
    }
  }

  #solutions {
    padding-top: 100px;

    .scroll-container {
      .Plx {
        min-height: 650px;
      }

      &:first-child {
        .Plx {
          .columns {
            margin-top: 80px;
          }
        }
      }
    }

    p {
      padding: 7px 0;

      i {
        left: 0;
      }
    }

    .pic {
      min-height: 460px;

      &.municiple img {
        width: 80%;
      }
    }

    .scroll-container {
      &.titled {
        height: calc(100vh - 85px);
      }
    }
  }

  #others {
    margin-top: 50px;

    .Plx {
      min-height: 900px;
    }

    .wrapper {
      margin-top: 50px;
      height: calc(100% - 152px);
    }
  }

  #advantages {
    padding: 80px 0px;

    h2 {
      margin-bottom: 80px;
    }

    .Plx {
      height: calc(100vh - 160px);
      min-height: 720px;
    }
  }

  #demo {
    height: 600px;
  }

  #contact {
    padding: 80px;

    .address {
      padding: 15px 0;

      .info {
        margin: 10px 0;
      }
    }

    form {
      input {
        height: 50px;
        margin-bottom: 15px;
      }

      textarea {
        height: 125px;
      }

      input,
      textarea {
        padding: 10px;
        font-size: 2rem;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  //middle fix

  section {
    h1 {
      font-size: 6rem;
    }
  }

  #others .wrapper {
    h6 {
      &.h1 {
        left: -100px;
      }

      &.h4 {
        right: 135px;
      }

      &.h6 {
        left: -240px;
      }

      &.h7 {
        left: 70px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  //horizontal view tablets

  .container-fluid {
    max-width: 970px;
    padding: 0 15px;
  }

  section {
    .Plx {
      min-height: 600px;
    }

    h1 {
      font-size: 4.8rem;
    }

    h2 {
      font-size: 4.8rem;
    }

    h4 {
      font-size: 2.6rem;
    }

    h6 {
      font-size: 1.4rem;
    }

    p {
      font-size: 1.8rem;
    }
  }

  header {
    .header-bg {
      width: 200%;
      height: 200%;
      left: -40%;
    }
  }

  #about {
    .video-container {
      height: 510px;
      .front {
        flex-direction: column;
        align-items: center;
        .left {
          margin-right: 0;
          margin-bottom: 7rem;
          i {
            height: 129px;
            width: 129px;
            &:before {
              height: 129px;
              width: 129px;
            }
            &:after {
              height: 129px;
              width: 129px;
            }
          }
        }
        .right {
          text-align: center;
          margin-left: 0;
          h2 {
            font-size: 3rem;
            &:before {
              width: 60%;
              margin-left: auto;
              margin-right: auto;
              left: 0;
              right: 0;
            }
          }
          span {
            font-size: 2.5rem;
          }
        }
      }
    }
  }

  #properties {
    &:before,
    &:after {
      width: 100%;
      background-size: cover;
    }

    .ocr {
      h1 {
        line-height: 52px;
      }

      .prop-holder {
        .props {
          &.prop1 p {
            left: 0 !important;
            right: 0 !important;
          }

          &.prop2 p,
          &.prop3 p {
            left: unset !important;
            right: 0 !important;
          }

          &.prop2 {
            right: -100px;
          }

          p {
            top: -25px !important;
            margin: auto;
            text-align: center;
          }
        }
      }
    }
  }

  #solutions {
    margin-top: 250px;

    .scroll-container {
      .right,
      .left {
        p {
          padding: 5px 0;

          i {
            line-height: 25px;
          }
        }
      }

      .Plx {
        min-height: 0;
        margin: 70px 0;
      }
    }
  }

  #others {
    margin-top: 100px;

    .Plx {
      min-height: 980px;
    }

    .mobileP {
      display: block !important;
    }

    .wrapper {
      h6 {
        display: none;
      }

      .prop {
        width: 135px !important;
        height: 135px !important;

        &.p2 {
          right: 20%;
        }

        &.p5 {
          bottom: 110px;
          right: 14%;
        }

        &.p8 {
          top: 90px;
        }

        &.p9 {
          top: 120px;
          left: 5%;
        }
      }
    }
  }

  #advantages {
    h2 {
      margin-bottom: 30px;
    }

    .Plx {
      height: auto;
      min-height: 980px;

      &--below {
        .left,
        .right {
          animation: none;
        }

        .para {
          animation: dropDown 1s cubic-bezier(0.17, 0.84, 0.44, 1) 1 both;

          &.p1 {
            animation-delay: 0.2s;
          }

          &.p2 {
            animation-delay: 0.4s;
          }

          &.p3 {
            animation-delay: 0.6s;
          }

          &.p4 {
            animation-delay: 0.8s;
          }

          &.p5 {
            animation-delay: 1s;
          }

          &.p6 {
            animation-delay: 1.2s;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  //vertical view tablets

  #loader {
    min-height: 580px;

    .load-holder {
      width: 210px;
      height: 190px;

      .bars {
        width: 18px;
        bottom: 35px;

        &.bar1 {
          left: 66px;
        }

        &.bar3 {
          right: 66px;
        }
      }
    }

    .load-text {
      p {
        margin: 30px 0;
        height: 80px;
        font-size: 2.6rem;
        padding: 0 10px;
      }
    }
  }

  header {
    min-height: 580px;

    .languages {
      span {
        font-size: 2rem;
        width: 65px;

        &:first-child:after {
          height: 25px;
        }
      }
    }

    .logo {
      img {
        width: 210px;
        height: 190px;
      }

      p {
        height: 80px;
        margin: 30px 0;
        font-size: 2.6rem;
        padding: 0 10px;
      }
    }

    .forward {
      width: 60px;
    }
  }

  .container-fluid {
    max-width: 720px;
  }

  section {
    .Plx {
      height: auto;
    }

    h1 {
      font-size: 3.4rem;
    }
  }

  #properties {
    &:before,
    &:after {
      width: 100%;
      background-size: cover;
    }

    .ocr {
      h1 {
        line-height: 44px;
      }

      .block-holder {
        height: 530px;

        .blocks {
          width: 60px;
        }
      }

      .prop-holder {
        width: 100%;
        display: flex;

        img {
          width: 80%;
          height: 80%;
          left: 0;
          right: 0;
          margin: auto;
        }

        .props.prop1 {
          left: 0;

          &:after {
            right: unset;
            left: 80%;
          }
        }

        .props.prop2 {
          right: 0;

          &:after {
            right: 70%;
            left: unset;
            height: 140px;
          }
        }

        .props.prop3 {
          right: 0;

          &:after {
            right: 80%;
            left: unset;
          }
        }
      }
    }

    .Plx--below .block-holder {
      .blocks {
        animation: none !important;
        position: relative;
        margin-right: 8vw;
      }
    }
  }

  #solutions {
    .scroll-container {
      &:first-child {
        .Plx {
          display: block;
        }
      }

      .Plx {
        flex-direction: column;

        .img {
          order: 1;
          margin-bottom: 30px;
        }

        .text {
          order: 2;
          width: auto;
        }
      }

      .columns {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .pic {
        padding: 50px 0;
        min-height: unset;

        img {
          width: 60% !important;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }

      &:nth-child(2) {
        .pic {
          img {
            width: 50% !important;
          }
        }
      }
    }
  }

  #others {
    margin-top: 100px;

    .Plx {
      min-height: 600px;
    }

    .wrapper {
      height: unset;
      margin-top: 30px;

      .prop,
      h6 {
        display: none;
      }

      .center-prop {
        width: 100%;
        height: 510px;

        .bgi {
          width: 60% !important;
          height: 80% !important;
        }

        img {
          width: 35%;
          height: 40%;
        }
      }
    }
  }

  #demo {
    height: 500px;

    .demo-pic {
      height: 550px;
    }
  }

  #contact {
    margin-top: 200px;
    padding: 15px;

    form {
      width: 100%;

      button {
        margin-top: 15px;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  //mobile

  .container-fluid {
    max-width: 540px;
  }

  section {
    h1 {
      font-size: 3rem;
    }

    h2 {
      font-size: 3.8rem;
    }

    h4 {
      font-size: 2.1rem;
    }

    h6 {
      font-size: 1.4rem;
    }

    p {
      font-size: 1.8rem;
    }
  }

  #about {
    .video-container {
      height: 330px;
      .front {
        .left {
          margin-bottom: 5rem;
          i {
            height: 90px;
            width: 90px;
            &:before {
              height: 90px;
              width: 90px;
            }
            &:after {
              height: 90px;
              width: 90px;
            }
          }
        }
        .right {
          h2 {
            font-size: 2.5rem;
          }
          span {
            font-size: 2rem;
          }
        }
      }
    }
    .video-description {
      font-size: 14px;
      line-height: 1.2;
      margin-top: 20px;
    }
  }

  #properties {
    .Plx {
      justify-content: unset;
    }

    .accordion {
      &__title {
        padding-left: 35px;

        &::before {
          font-size: 3.2rem;
        }
      }
    }

    .ocr {
      h1 {
        line-height: 36px;
        text-align: center;
        margin: 15px 0;
      }

      .block-holder {
        height: 80vw;
        .blocks {
          width: 10vw;
        }
      }

      .prop-holder {
        .props {
          width: 80px !important;
          height: 80px !important;

          span {
            font-size: 3rem;
          }

          &.prop1 {
            &:after {
              width: 70px;
            }
          }

          &.prop2 {
            &:after {
              width: 150px;
              height: 125px;
            }
          }

          &.prop3 {
            &:after {
              width: 80px;
            }
          }
        }
      }
    }
  }

  #others {
    .wrapper {
      .center-prop {
        height: 400px;

        .bgi {
          height: 60% !important;
        }
      }
    }
  }

  #contact {
    .address {
      .info {
        flex-direction: column;
        margin: 3px 0;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  #properties .ocr {
    h1 {
      line-height: 30px;
    }

    .prop-holder .props {
      width: 70px !important;
      height: 70px !important;

      &.prop2:after {
        width: 110px;
        height: 85px;
      }
    }
  }
}

#root {
  visibility: initial;
}
