
@keyframes bloom { 
  0% { -webkit-transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  3.2% { -webkit-transform: matrix3d(0.324, 0, 0, 0, 0, 0.324, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.324, 0, 0, 0, 0, 0.324, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  6.31% { -webkit-transform: matrix3d(0.538, 0, 0, 0, 0, 0.538, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.538, 0, 0, 0, 0, 0.538, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  12.51% { -webkit-transform: matrix3d(0.786, 0, 0, 0, 0, 0.786, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.786, 0, 0, 0, 0, 0.786, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.82% { -webkit-transform: matrix3d(0.903, 0, 0, 0, 0, 0.903, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.903, 0, 0, 0, 0, 0.903, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  25.03% { -webkit-transform: matrix3d(0.956, 0, 0, 0, 0, 0.956, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.956, 0, 0, 0, 0, 0.956, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  37.54% { -webkit-transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  50.05% { -webkit-transform: matrix3d(0.998, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.998, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } 
}

@keyframes pulse {

  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}

@keyframes slideFromLeft {
	0% {
    opacity: 1;
    transform: translateX(-20%);
	}

	100% {
    opacity: 1;
		transform: translateX(0);
	}
}

@keyframes slideFromRight {
	0% {
    opacity: 1;
		transform: translateX(20%);
	}

	100% {
    opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fadeOut {
	0% {
    opacity: 1;
	}

	100% {
    opacity: 0;
    visibility: hidden;
	}
}


@keyframes angleFromLeft1 {
	0% {
    left: 0;
  }
  
	100% {
		left: 90px;
	}
}

@keyframes angleFromLeft2 {
	0% {
    left: 0;
  }
  
	100% {
		left: 330px;
	}
}

@keyframes angleFromLeft3 {
	0% {
    left: 0;
  }
  
	100% {
		left: 570px;
	}
}

@keyframes cellularBgLeft {
  0% {
    background-size: 100%;
		transform: rotate(0deg);
  }
  
  50% {
    background-size: 80%;
		transform: rotate(-180deg);
  }

	100% {
		background-size: 100%;
		transform: rotate(-360deg);
	}
}

@keyframes cellularBgRight {
  0% {
    background-size: 100%;
		transform: rotate(0deg);
  }
  
  50% {
    background-size: 80%;
		transform: rotate(180deg);
  }

	100% {
		background-size: 100%;
		transform: rotate(360deg);
  }
}

@keyframes rotateBgLeft {
  0% {
    background-size: 100%;
		transform: rotate(0deg);
  }
  
  50% {
    background-size: 80%;
		transform: rotate(-180deg);
  }

	100% {
		background-size: 100%;
		transform: rotate(-360deg);
	}
}

@keyframes rotateBgRight {
  0% {
		transform: scale3d(1, 1, 1) rotate(0deg);
  }
  
  50% {
		transform: scale3d(0.8, 0.8, 1) rotate(180deg);
  }

	100% {
		transform: scale3d(1, 1, 1) rotate(360deg);
  }
}

@keyframes cellularMove1 {

  15% {
    transform: scale3d(1.05, 0.90, 1), rotate(5deg);
  }

  30% {
    transform: scale3d(1.00, 0.95, 1) rotate(-10deg);
  }

  40% {
    transform: scale3d(1.10, 1.00, 1) rotate(0deg);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1) rotate(10deg);
  }

  65% {
    transform: scale3d(1.10, 0.95, 1) rotate(15deg);
  }

  75% {
    transform: scale3d(1.0, 0.90, 1) rotate(5deg);
  }

  90% {
    transform: scale3d(1.05, 1.00, 1) rotate(0deg);
  }
}

@keyframes cellularMove2 {

  15% {
    transform: scale3d(1.10, 0.95, 1) rotate(-5deg);
  }

  30% {
    transform: scale3d(1.00, 0.90, 1) rotate(0deg);
  }

  40% {
    transform: scale3d(1.05, 1.00, 1) rotate(-10deg);
  }

  50% {
    transform: scale3d(1.10, 0.95, 1) rotate(5deg);
  }

  65% {
    transform: scale3d(1.15, 1.00, 1) rotate(15deg);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1) rotate(0deg);
  }

  90% {
    transform: scale3d(1.00, 0.85, 1) rotate(-5deg);
  }
}

@keyframes slide {

  0% { 
    opacity: 0; 
    transform: translateY(33px); 
  }	

  20% { 
    opacity: 1; 
    transform: translateY(16.5px); 
  }	

  80% { 
    opacity: 1; 
    transform: translateY(-16.5px); 
  }	

  100% { 
    opacity: 0; 
    transform: translateY(-33px); 
  }	
}

@keyframes loading {

  0% {
    height: 3px;
  }

  100% {
    height: 163px;
  }
}

@keyframes curtain {

  0% {
    width: 100%;
  }

  100% {
    width: 0;
  }
}

@keyframes dropDown {

  0% { 
    opacity: 0; 
    transform: translateY(-20%); 
  }	

  100% { 
    opacity: 1; 
    transform: translateY(20%); 
  }	
}

@media only screen and (max-width: 1823px) {

  @keyframes angleFromLeft1 {
    0% {
      left: 0;
    }
    
    100% {
      left: 70px;
    }
  }
  
  @keyframes angleFromLeft2 {
    0% {
      left: 0;
    }
    
    100% {
      left: 270px;
    }
  }
  
  @keyframes angleFromLeft3 {
    0% {
      left: 0;
    }
    
    100% {
      left: 470px;
    }
  }
}

@media only screen and (max-width: 1440px) {

  @keyframes angleFromLeft1 {
    0% {
      left: 0;
    }
    
    100% {
      left: 50px;
    }
  }
  
  @keyframes angleFromLeft2 {
    0% {
      left: 0;
    }
    
    100% {
      left: 200px;
    }
  }
  
  @keyframes angleFromLeft3 {
    0% {
      left: 0;
    }
    
    100% {
      left: 350px;
    }
  }
}

@media only screen and (max-width: 768px) {

  @keyframes angleFromLeft1 {
    0% {
      left: 0;
    }
    
    100% {
      left: 30px;
    }
  }
  
  @keyframes angleFromLeft2 {
    0% {
      left: 0;
    }
    
    100% {
      left: 120px;
    }
  }
  
  @keyframes angleFromLeft3 {
    0% {
      left: 0;
    }
    
    100% {
      left: 210px;
    }
  }

  @keyframes loading {

    0% {
      height: 3px;
    }
  
    100% {
      height: 120px;
    }
  }
}

@media only screen and (max-width: 576px) {

  @keyframes angleFromLeft1 {
    0% {
      left: 0;
    }
    
    100% {
      left: 5vw;
    }
  }
  
  @keyframes angleFromLeft2 {
    0% {
      left: 0;
    }
    
    100% {
      left: 20vw;
    }
  }
  
  @keyframes angleFromLeft3 {
    0% {
      left: 0;
    }
    
    100% {
      left: 35vw;
    }
  }
}